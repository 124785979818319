.actionIcon {
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
}

.manageFirewallLink {
  cursor: pointer;
  color: #005789;
  transition: all 0.3s ease;
  width: 150px;
  &:hover {
    color: #0057898e;
  }
}

.lastUpdate {
  p {
    overflow-wrap: normal;
    white-space: nowrap;
    margin-bottom: 0;
  }
}

.actionColumn {
  width: 100px;
}
