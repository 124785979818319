.iconContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 64px;
  color: #1677ff;
}

.textContainer {
  margin: 20px 0;
  text-align: center;
  h1 {
    font-size: 24px;
  }
}
