.title {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  span {
    padding-right: 5px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      color: #eed151;
    }
  }
}
