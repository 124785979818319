.loaderContainer {
  background: #00000090;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
}

.loaderContainerOpaque {
  background: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
}

.loader {
  margin: 25% 50% 25% 50%;
}
