@use "styles/base" as base;

.container {
  position: fixed;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: base.$dark_yellow;
  width: 100%;
  padding: 5px 0;
  font-size: 14px;
  z-index: 999;

  strong {
    margin-right: 5px;
  }
}

.banner {
  position: fixed;
  top: 25px;
  left: -35px;
  background-color: base.$dark_yellow;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 3px;
  width: 150px;
  transform: rotate(-45deg);
  text-align: center;
  font-weight: 700;
}
