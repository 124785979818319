@import "styles/base.scss";

.card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.deviceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  span {
    border-radius: 100%;
    background-color: $yellow;
    padding: 20px;
  }
  svg {
    width: 40px;
    height: 40px;
    fill: $dark_blue;
  }
}

.headerRow {
  margin-bottom: 40px;
}

.featureIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
}

.featureItem {
  padding: 0px 0px 20px;
}

.featureRow {
  margin: 20px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.05);
    opacity: 0.5;
    right: 0;
    bottom: 0;
  }
  &:global(.last) {
    &::before {
      display: none;
    }
  }
}

.checkIcon {
  color: #95de64;
}

.closeIcon {
  color: #ff4d4f;
}

.tba {
  font-weight: 800;
  color: $yellow;
}
