@import "styles/base.scss";

.container {
  margin: 0px auto;
  padding-top: 60px;
  h1 {
    font-family: $main_font;
    margin-bottom: 30px;
  }
}

.accordion {
  margin-bottom: 20px;
  :global(.ant-collapse) {
    border-radius: 8px;
  }

  :global(.ant-collapse-header-text) {
    font-weight: 700;
  }

  :global(.ant-collapse-item.ant-collapse-item-active) {
    :global(.ant-collapse-header) {
      background-color: $light_blue;
      color: $white !important;
    }
  }
}
