.container {
  &.danger {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }
  &.warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  &.success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  padding: 20px;
  h1 {
    font-size: 16px !important;
    margin-bottom: 5px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.button {
  &.danger {
    background-color: #f5222d !important;
    border: 1px solid #f5222d !important;
  }
  &.warning {
    background-color: #d4b106 !important;
    border: 1px solid #d4b106;
  }
  &.success {
    background-color: #52c41a !important;
    border: 1px solid #52c41a !important;
  }
}
