.button {
  background-color: #eed151;
  border-color: #eed151;
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: 7px 7px 7px 7px;
  padding-top: 10px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-left: 40px;
  &:hover {
    background-color: #ecc30e;
    border-color: #ecc30e;
    color: black;
    font-weight: bold;
    border-radius: 7px 7px 7px 7px;
  }
  &:disabled {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    cursor: not-allowed;
  }
}
