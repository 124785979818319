.optionContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid #c6c6c6;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease;
  box-shadow: none;
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding-right: 12px;
  }
}

.description {
  font-size: 10px;
  font-weight: 700;
}

.label {
  font-weight: 400;
  font-size: 14px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
