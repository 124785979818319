.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.card {
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  height: 100%;
  :global(.ant-card-body) {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.title {
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
}

.price {
  .amount {
    font-size: 20px;
    font-weight: 800;
  }
  .interval {
    font-size: 16px;
  }
}

.description {
  font-size: 14px;
  font-weight: normal;
}

.button {
  margin-top: 20px;
  width: 100%;
  align-self: flex-end;
  button {
    width: 100%;
  }
}

.priceDesc {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  border-radius: 10px;
}
