.card {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: none;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  }
  &:global(.disabled) {
    color: #bebebe;
    &:hover {
      box-shadow: none !important;
      .cta {
        color: #69b1ff;
      }
    }
    .cta {
      color: #007bff;
    }
  }
}

.iconContainer {
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
}

.icon {
  background-color: #e0e0e0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 20px;
  width: 50px;
  height: 50px;
}

.title {
  font-size: 18px;
  transition: all 0.3s ease;
}

.description {
  font-size: 16px;
}

.ctaContainer {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  span {
    position: relative;
    top: 0.3px;
    margin-left: 3px;
  }
}
