.section {
  text-align: center;
  min-height: 70vh;
  display: flex;
  align-items: center;
}

.image {
  width: 80px;
  height: 80px;
}
