.textCarouselItem {
  color: #005689;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.slogan {
  h2 {
    font-size: 32px;
  }
}
