$color_dimmer_first: #f9eec0;
$color_dimmer_second: #fafafa;

@keyframes color_dimmer {
  0% {
    background-color: $color_dimmer_first;
  }

  25% {
    background-color: $color_dimmer_second;
  }

  50% {
    background-color: $color_dimmer_first;
  }

  75% {
    background-color: $color_dimmer_second;
  }

  100% {
    background-color: $color_dimmer_first;
  }
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}
