.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  padding-top: 30px;
}

.downloadButton {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: max-content !important;
  position: relative;
  &:disabled {
    &:hover {
      h1 {
        color: #000;
      }
    }
  }
  &:hover {
    h1 {
      color: #40a9ff;
    }
  }
  svg {
    width: auto;
    height: 30px;
    margin: 20px 0 5px;
  }
  .buttonContent {
    width: 100%;
    margin: 5px 0 20px;
    span {
      font-size: 10px;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  h1 {
    font-size: 16px;
    margin-bottom: 0;
    transition: all 0.3s ease;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}
