.actionIcon {
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
}

.firewallRuleName {
  white-space: nowrap;
  overflow-wrap: normal;
}

.vpcName {
  min-width: 80px;
  display: inline-block;
}

.projectName {
  width: 200px;
  display: inline-block;
  margin-bottom: 0 !important;
}

.firewallIpVersion {
  min-width: 120px;
  display: inline-block;
}

.placeholderIpAddress {
  min-width: 150px;
  display: inline-block;
}

.updateMode {
  min-width: 150px;
  display: inline-block;
}

.status {
  min-width: 150px;
}

.action {
  min-width: 100px;
  width: 150px;
}

.manageFirewallLink {
  cursor: pointer;
  color: #005789;
  transition: all 0.3s ease;
  width: 150px;
  &:hover {
    color: #0057898e;
  }
}

.locationLogo {
  margin-right: 10px;
  img {
    width: auto;
    height: 20px;
    object-fit: contain;
  }
}

.credentialLocation {
  min-width: 90px;
}
