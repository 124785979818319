.menu {
  li {
    // color: #ecc30e !important;
    &:hover {
      a {
        color: #ecc30e !important;
        text-decoration: none !important;
      }
      &::after {
        border: 2px solid #ecc30e !important;
      }
    }
    &:global(.ant-menu-item-selected) {
      a {
        color: #ecc30e !important;
      }
      &::after {
        border: 2px solid #ecc30e !important;
      }
    }
  }
}
