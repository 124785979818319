.action {
  cursor: pointer;
  span {
    top: -2px;
    position: relative;
  }
  &:hover {
    color: #ff4d4f;
  }
}
