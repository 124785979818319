.featuredItem {
  margin-bottom: 70px;
  &:nth-child(2n) {
    .textContainer {
      order: 2;
    }
    .imageContainer {
      order: 1;
    }
  }
}
