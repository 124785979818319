body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/* -- */

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content {
  background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price {
  background-color: #f6f6f6;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head_bg {
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head
  span {
  color: #525252;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .sign {
  color: #414141;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .currency {
  color: #414141;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .cent {
  color: #414141;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .month {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
  color: #414141;
}
#generic_price_table .generic_content .generic_feature_list ul li:hover {
  background-color: #e4e4e4;
  border-left: 5px solid #2ecc71;
}

#generic_price_table .generic_content .generic_price_btn a {
  border: 1px solid #2ecc71;
  color: #2ecc71;
}

#generic_price_table
  .generic_content.active
  .generic_head_price
  .generic_head_content
  .head_bg,
#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head_bg {
  border-color: #2ecc71 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #2ecc71;
  color: #fff;
}

#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head
  span,
#generic_price_table
  .generic_content.active
  .generic_head_price
  .generic_head_content
  .head
  span {
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
  background-color: #2ecc71;
  color: #fff;
}
#generic_price_table {
  margin: 50px 0 50px 0;
}
.row .table {
  padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
  overflow: hidden;
  position: relative;
  text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content {
  margin: 0 0 50px 0;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head_bg {
  border-style: solid;
  border-width: 90px 1411px 23px 399px;
  position: absolute;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head {
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head
  span {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
  padding: 0 0 20px;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price {
  display: block;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .sign {
  display: inline-block;
  font-size: 28px;
  font-weight: 400;
  vertical-align: middle;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .currency {
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 60px;
  padding: 0;
  vertical-align: middle;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .cent {
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  vertical-align: bottom;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .month {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  font-size: 18px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li:hover {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li .fa {
  padding: 0 10px;
}
#generic_price_table .generic_content .generic_price_btn {
  margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-size: 18px;
  outline: medium none;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head_bg,
#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head_bg,
#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head
  h2,
#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head
  h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}

.nav-container {
  background-color: #f3f3ef;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.primary-button {
  background-color: #eed151 !important;
  border-color: #eed151 !important;
  color: black !important;
  font-weight: bold !important;
  font-size: 18px !important;
  border-radius: 7px 7px 7px 7px !important;
  &:hover {
    background-color: #ecc30e !important;
    border-color: #ecc30e !important;
  }
}

.button-padding {
  padding-top: 10px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.footer-container {
  background-color: #005789;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@font-face {
  font-family: "Exo";
  src: url("https://fonts.gstatic.com/s/exo/v15/4UaOrEtFpBISc36j.woff2")
    format("woff");
  font-weight: bold;
}

.h2 {
  font-family: "Exo", Sans-serif;
  color: #1b2a37;
  font-size: 42px;
  font-weight: bold;
  line-height: 1.3em;
  margin-bottom: 20px;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 320px) {
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table .col-md-3 {
    float: left;
    width: 50%;
  }

  #generic_price_table .col-md-4 {
    float: left;
    width: 50%;
  }

  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) {
}

// ========= ANT Design ===========
.ant-tour-buttons {
  display: none;
}
