.header {
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.section {
  margin-top: 30px;
}
