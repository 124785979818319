@import "styles/base.scss";

.footer_container {
  background-color: $light_blue !important;
  color: $white;
}

.logo {
  width: 200px;
}

.content_container {
  padding: 30px 0 30px;
}

.download_btn {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
}

.menu_list {
  padding-left: 50px;
  .divider {
    margin: 10px 0 !important;
    width: 50% !important;
    min-width: 50% !important;
    border-top: 2px solid $white !important;
  }
  .menu_item {
    margin-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      font-weight: 700;
      color: $yellow;
    }
  }
}

.divider {
  margin: 30px 0 !important;
  border-top: 1px solid $white !important;
}

.social_icon_container {
  margin: 10px auto 10px;
  .social_icon {
    cursor: pointer;
    svg {
      transition: all 0.3s ease;
      fill: $white;
      width: 28px;
      height: 28px;
    }

    &:hover {
      svg {
        fill: $yellow;
      }
    }
  }
}
