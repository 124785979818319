.progressTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  &:global(.danger) {
    color: #f5222d;
  }
  span {
    position: relative;
    top: -1px;
  }
}
