.header {
  margin-bottom: 20px;
  padding: 0;
}

.layout {
  margin-left: 200px;
  transition: all 0.3s ease;
  &.layoutCollapsed {
    margin-left: 80px;
  }
}
