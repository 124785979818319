.navBarItem {
  a {
    position: relative;
    padding: 12px 20px;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    justify-content: center;
    cursor: pointer;
  }

  ::after {
    position: absolute;
    content: "";
    opacity: 0;
    display: block;
    bottom: 0;
    height: 3px;
    width: 100%;
    left: 0;
    background-color: #55595c;
    z-index: 2;
    transition: 0.4s;
  }

  &:hover {
    ::after {
      opacity: 1;
    }
  }
}
