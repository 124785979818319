.header {
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.details_container {
  width: 100%;
  :global(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
  }
}
