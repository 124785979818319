.details_container {
  width: 100%;
  :global(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
  }

  :global(.ant-descriptions-item-content) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  :global(.ant-tag) {
    margin-right: 0;
  }
}

.header {
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.table {
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      background-color: #002140 !important;
      font-weight: bold;
      color: #ffffff;
    }
  }

  :global(.ant-table-cell) {
    background-color: #ffffff !important;
  }
}

.odd_rows {
  :global(.ant-table-cell) {
    background-color: #e6f4ff !important;
    border: 1px solid #ffffff;
  }
}
