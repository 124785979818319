.header {
  width: calc(100% - 32px);
  margin: 24px auto 0px;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 0 16px !important;
}

.container {
  margin-right: 10px;
  padding: 0 10px;
  position: relative;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    right: 0;
    height: 70%;
    margin: auto 0;
    width: 1px;
    background-color: #e0e0e0e0;
  }
  &:last-child {
    &::before {
      background-color: #fff;
    }
  }
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.avatar {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #eed151 !important;
}

.notification {
  display: flex;
  :global(.ant-badge) {
    padding: 5px;
    background: #eed151;
    border-radius: 100%;
  }
  :global(.anticon) {
    color: #fff !important;
    font-size: 20px;
  }
}
