.tableTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.tableLogo {
  margin-right: 10px;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}
