.option {
  :global(.ant-segmented-item) {
    margin-bottom: 0 !important;
  }
}

.contentContainer {
  margin-top: 30px;
}

.withoutAgentContainer {
  p {
    margin-bottom: 5px;
  }
  input {
    margin-bottom: 5px;
  }
}

.withoutAgentButton {
  width: 100%;
  text-align: right;
}

.downloadButton {
  background-color: #ecc30e;
  &:hover {
    color: #ecc30e;
  }
}
