.header {
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.table {
  width: 100%;
}

.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
