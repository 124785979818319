@import "styles/base.scss";

.selection {
  text-align: center;
  margin-top: 30px;
  label {
    margin-bottom: 0;
  }
}

.priceDetailsSection {
  margin-top: 120px;
  h1 {
    margin-bottom: 20px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
  }
  p {
    margin-bottom: 0;
  }
}

.panel {
  :global(.ant-collapse-header) {
    background-color: #002140;
    color: #fff !important;
    font-weight: bold;
    padding: 18px 16px !important;
  }
  :global(.ant-collapse-arrow) {
    vertical-align: 2px !important;
  }
  :global(.ant-collapse-content-box) {
    padding: 0 20px !important;
  }
}

.detailsItemsContainer {
  &:last-child {
    .divider {
      display: none;
    }
  }
}

.detailsItems {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.checkIcon {
  font-size: 24px;
  color: #95de64;
}

.crossIcon {
  font-size: 24px;
  color: #ff4d4f;
}

.selectedPlan {
  background-color: #ecc30e3d;
}

.planTitle {
  margin: auto;
  padding: 20px 0;
  h1 {
    margin-bottom: 0 !important;
  }
}

.planDetails {
  min-height: 125px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  p {
    padding: 20px 0;
  }
}

.divider {
  margin: 0 !important;
}

.tableTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $yellow;
    cursor: pointer;
  }
}
