.container {
  margin-top: 20px;
}

.section {
  margin-bottom: 80px;
}

.countTitle {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.countIcon {
  margin-right: 5px;
  top: -4px;
  position: relative;
}

.detailCardTitle {
  h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.progressTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  &:global(.danger) {
    color: #f5222d;
  }
  span {
    position: relative;
    top: -1px;
  }
}
