.section {
  padding: 30px 50px;
}

.textContainer {
  h1 {
    font-size: 52px;
    font-weight: 900;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
  }
}

.imageContainer {
  position: relative;
  img {
    position: relative;
    object-fit: contain;
    width: 100%;
  }
}
