@import "styles/base.scss";

.actionIcon {
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }

  &.highlight {
    animation: breathe 7s ease-out infinite normal;
  }
}

.locationLogo {
  margin-right: 10px;
  img {
    width: auto;
    height: 20px;
    object-fit: contain;
  }
}
