@use "styles/base.scss" as base;

.featured {
  h3 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 24px;
  }
  p {
    margin: 0;
  }
}

.priceContainer {
  border: 2px solid #eed151;
  background-color: #eed1517b;
  margin-bottom: 10px;
  p {
    margin: 0;
    padding: 5px;
    font-weight: 800;
    color: #000000;
    text-align: center;
  }
}

.featuredQtyStatus {
  margin-top: 40px !important;
  color: base.$dark_yellow;
  font-weight: 800;
}

.volumePrice {
  margin: 5px 0 15px !important;
}

.qtyContainer {
  margin-bottom: 10px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.quantityActionButton {
  background-color: #eed151;
  color: #000000;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgb(238, 210, 83, 0.7);
  }

  &:global(.disabled) {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
}

.plusQuantity {
  border-radius: 0px 7px 7px 0px;
}

.minQuantity {
  border-radius: 7px 0px 0px 7px;
}

.quantityInput {
  height: 100%;
  text-align: center;
  border-radius: 0px !important;
}

.button {
  width: 100%;
}
