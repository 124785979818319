.dropdownContainer {
  min-width: 125px;
  li {
    height: 30px !important;
  }
  :global(.ant-dropdown-menu-item-divider) {
    height: 1px !important;
    width: 80%;
    margin: 10px auto;
  }
}

.dropdownMenu {
  display: flex;
  align-items: center;
  gap: 10px;
}
