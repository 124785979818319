.priceHeader1 {
  text-align: center;
  font-weight: bold;
  font-family: "Exo";
}

.priceHeader2 {
  text-align: center;
  font-weight: lighter;
}

.pricePlanButton {
  display: block;
  width: fit-content;
  margin: 20px auto 20px auto;
}

.priceMain {
  box-shadow: 0 0 24px #00000026;
  width: 1140px;
  margin: 0 auto;
}

.priceTable {
  width: 100%;
  border-collapse: collapse;
  border: 0 none;
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    }
    td {
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      padding: 8px 24px;
      font-size: 14px;
      &:first-child {
        border-left: 0 none;
      }
      &:not(:first-child) {
        text-align: center;
      }
      //? Highlight
      &:nth-child(4) {
        background-color: rgba(216, 214, 227, 0.15);
        padding: 8px 48px;
      }
    }
    &:nth-child(even) {
      background-color: #ffffff;
    }
    &:hover {
      background-color: #eeeeee;
    }
    //? Highlight
    &:nth-child(2n) {
      td {
        &:nth-child(4) {
          background-color: rgba(216, 214, 227, 0.25);
        }
      }
    }
  }
  :global(.fa-check) {
    // color: #005789;
    color: #52c41a;
    filter: drop-shadow(3px 4px 2.5px rgb(0 0 0 / 0.4));
  }
  :global(.fa-xmark) {
    // color: #d8d6e3;
    color: #f5222d;
    filter: drop-shadow(3px 4px 2.5px rgb(0 0 0 / 0.4));
  }

  svg {
    width: 90px;
    fill: #005789;
  }
}

.priceTableHead {
  background-color: #005789 !important;
  color: #ffffff !important;
  td {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.priceLabel {
  width: calc(100% / 5);
  color: #f43f54 !important;
  padding: 24px !important;
  font-size: 16px !important;
  font-weight: 600;
  a {
    background-color: #005789;
    color: #ffffff;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
  }
  button {
    margin-top: 10px;
  }
}

.priceTablePopular {
  border-top: 3px solid #005789;
  color: #005789;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 48px;
  font-weight: 700;
}

.priceTableBlank {
  background-color: #fafafa;
  border: 0 none;
}

.priceSubHead {
  font-size: 12px;
  font-weight: 400;
}

.pricingSubHead {
  background-color: #005789 !important;
  color: #fafafa !important;
}

.priceHelp {
  color: #005789;
}

.priceHelpContainer {
  display: flex;
  align-content: center;
  span {
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: auto;
    }
  }
}

.priceTableButton {
  padding: 24px !important;
}

.priceSkeleton {
  margin: 10px 0;
  :global(.ant-skeleton-paragraph) {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
