.section {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.title {
  font-size: 160px;
  font-weight: 700;
  margin-bottom: 0;
}

.titleContainer {
  margin-bottom: 30px;
  h1 {
    color: #005789;
  }
}
