@import "styles/base.scss";

.header {
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.highlight {
  :global(.ant-table-cell) {
    animation: color_dimmer 7s ease-out infinite normal;
  }
}

.tour {
  color: red;
}
