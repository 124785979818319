.iconContainer {
  font-size: 22px;
  display: flex;
  padding-top: 2px;
}

.settingContainer {
  h1 {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 800;
  }
  p {
    margin-bottom: 0 !important;
  }

  .descriptionContainer {
    margin-bottom: 15px;
  }
  .description {
    font-size: 18px;
  }
  .subDescription {
    font-size: 16px;
  }
}

.button {
  display: flex !important;
  align-items: center;
  background-color: #eed151 !important;
  border: none !important;
  color: #000 !important;
  font-weight: 600 !important;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ecc30e !important;
  }
}
