@import "styles/base.scss";

.image {
  position: relative;
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  h1 {
    font-family: $main_font;
  }
  p {
    width: 90%;
    font-size: 16px;
  }
}

.download_btn {
  position: relative;
  color: $dark_blue;
  font-weight: 700;
  margin-top: 5px;
}

.download_btn_container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  background-color: $yellow;
  padding: 6px 0;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transition: all 0.3s ease;
  border: 2px solid $yellow;
  min-height: 50px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    svg {
      width: 20px;
      height: 20px;
      fill: $dark_blue;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: $white;
  }

  &:global(.disabled) {
    background-color: #0000000a;
    border: 2px solid #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
      background-color: #0000000a;
    }
  }
}
