.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 80vh;
}

.container {
  width: 1140px;
}
