.sider {
  background: #fff !important;
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
}

.logoContainer {
  width: 100%;
  padding: 20px;
  cursor: pointer;
}
.logo {
  position: relative;
  object-fit: contain;
  width: 100%;
}

.menuContainer {
  :global(.ant-menu-item-active) {
    color: rgb(0, 87, 137) !important;
    a {
      color: rgb(0, 87, 137) !important;
    }
  }
  :global(.ant-menu-item-selected) {
    background-color: #eed1515e !important;
    color: #000 !important;
    &:global(.ant-menu-item-active) {
      color: rgb(0, 87, 137) !important;
    }
    a {
      color: #000;
    }
    &::after {
      border-right: 3px solid #eed151 !important;
    }
  }
}
