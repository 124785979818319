.imageContainer {
  :global(.ant-image) {
    width: 100%;
  }
  img {
    width: 100%;
    border-radius: 100%;
  }
}

.userDetailsContainer {
  h3 {
    font-size: 18px;
  }
}

.button {
  text-align: end;
  margin: 30px 0 10px;
}
