.timelineImg {
  position: absolute;
  top: 5%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 5%;
  text-align: center;
}

.contentContainer {
  .imageContainer {
    padding-left: 0;
    padding-right: 3rem;
  }
  .textContainer {
    padding-left: 3rem;
    padding-right: 0;
  }

  &:nth-child(2n + 1) {
    .imageContainer {
      order: 2;
      padding-left: 3rem;
      padding-right: 0;
    }
    .textContainer {
      order: 1;
      padding-left: 0;
      padding-right: 3rem;
    }
  }
}
