.section {
  margin-top: 30px;
  width: 100%;
  :global(.ant-spin-nested-loading) {
    width: 100%;
  }
}

.mainSection {
  min-height: 300px;
}
