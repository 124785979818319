.contentContainer {
  margin-top: 0px;
}

.withoutAgentContainer {
  p {
    margin-bottom: 5px;
  }
  input {
    margin-bottom: 20px;
  }
}

.withoutAgentButton {
  width: 100%;
  text-align: right;
}
