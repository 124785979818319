.section {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.container {
  width: 1140px;
}
